import React, { Suspense, useState } from 'react';
import ScannerPage from 'components/scanner/Scanner';
import VehicleInfo from 'components/vehicle/Vehicle';
import Certification from 'components/certification/certification';
import { Provider } from 'react-redux';
import store from 'store/store';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { STRING_CONSTANTS } from 'constants/constants';
import { MFPayload } from 'models/MFPayload';
import { Vehicle } from 'store/slice/vehicle/vehicle.types';

type ActivePage = 'scanner' | 'vehicleSplash' | 'certification';

const App: React.FC<MFPayload> = (props) => {
  const [activePage, setActivePage] = useState<ActivePage>('scanner');
  const [certAssistData, setCertAssistData] = useState<{
    locationCode?: string;
  }>({});
  const [vehicleData, setVehicleData] = useState<Vehicle | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleNavigation = (
    page: ActivePage,
    certAssistData?: { locationCode?: string },
    vehicleData?: Vehicle,
    resetSuccessMessage: boolean = false
  ) => {
    if (resetSuccessMessage) {
      setSuccessMessage(null);
    }
    setActivePage(page);
    if (certAssistData) {
      setCertAssistData(certAssistData);
    }
    if (vehicleData) {
      setVehicleData(vehicleData);
    }
  };

  return (
    <Provider store={store}>
      <InterstateThemeProvider
        themeName='Interstate'
        applicationName={STRING_CONSTANTS.APP_NAME}
        scopeName='scope of your application'
        themeRegistries={[interstateThemeRegistry]}
      >
        <Suspense fallback={<div>Loading...</div>}>
          {activePage === 'scanner' && (
            <ScannerPage
              {...props}
              onSubmit={(locationCode, vehicleData) =>
                handleNavigation(
                  'vehicleSplash',
                  { locationCode },
                  vehicleData,
                  true
                )
              }
              successMessage={successMessage}
            />
          )}
          {activePage === 'vehicleSplash' && (
            <VehicleInfo
              {...props}
              locationCode={certAssistData.locationCode}
              onContinue={() => handleNavigation('certification')}
              onBack={() =>
                handleNavigation('scanner', undefined, undefined, true)
              }
              vehicleData={vehicleData}
            />
          )}
          {activePage === 'certification' && (
            <Certification
              {...props}
              locationCode={certAssistData.locationCode}
              onBack={() =>
                handleNavigation('scanner', undefined, undefined, true)
              }
              onSubmitComplete={() => {
                setSuccessMessage(
                  'Your answers have been stored successfully.'
                );
                handleNavigation('scanner');
              }}
            />
          )}
        </Suspense>
      </InterstateThemeProvider>
    </Provider>
  );
};

export default App;

/* The Vehicle Splash Page displays a preview of the vehicle data by retrieving it from a DDS API call. 
Clicking the Continue button will take the user to the Certification Landing Page. */

// React and Hooks
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Redux and Store
import { RootState } from 'store/store';

// Strings
import { saveScanData } from 'services/recentScan/recentScanService';
import { Scan, RecentScan } from 'services/recentScan/recentScan.types';
import { MFPayload } from 'models/MFPayload';

import {
  VehicleInfoUI,
  VehicleInfoProps,
  VehicleInfoData
} from '@checkpoint/common-components';
import { Vehicle } from 'store/slice/vehicle/vehicle.types';
// Function to format the number
const formatNumber = (number: number) => {
  return number.toLocaleString();
};

type VehicleProps = MFPayload & {
  locationCode?: string;
  onContinue: () => void;
  onBack: () => void;
  vehicleData: Vehicle | null;
};
// Function to format text to title case
const formatToTitleCase = (units: string): string => {
  return units
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const VehicleInfo: React.FC<VehicleProps> = (props) => {
  const vehicleState = useSelector((state: RootState) => state.vehicle.data);

  const userId = props.userDetails?.userId;
  const { locationCode } = props;

  function createScanData(): string {
    const vehicle = vehicleState;
    if (vehicle && userId) {
      const scan: Scan = {
        userId: userId,
        locationCode: locationCode,
        consignmentId: vehicle.consignmentId,
        workOrderNumber: vehicle.workOrderNumber,
        vin: vehicle.vin,
        shortDescription: vehicle.shortDescription,
        exteriorNormalizedName: vehicle.exteriorNormalizedName,
        interiorNormalizedName: vehicle.interiorNormalizedName
      };
      return JSON.stringify(scan);
    }
    return '';
  }

  // Save last scanned record to DB on page load.
  useEffect(() => {
    if (vehicleState && userId) {
      const saveRecentScan = async () => {
        try {
          const currentDate = new Date();
          const lastScannedTime = currentDate.toISOString();
          const params: RecentScan = {
            pk: userId,
            consignmentId: vehicleState?.consignmentId!,
            lastScannedTime: lastScannedTime,
            scanData: createScanData()
          };
          await saveScanData(params);
        } catch (err) {
          console.error(err);
        }
      };
      saveRecentScan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleState, userId, locationCode]);

  function handleContinueClick() {
    props.onContinue();
  }
  function handleBackClick() {
    props.onBack();
  }

  const vehicleInfo: VehicleInfoData = {
    workOrderNumber: vehicleState?.workOrderNumber,
    companyName: vehicleState?.companyName,
    manheimAccountNumber: vehicleState?.manheimAccountNumber,
    vin: vehicleState?.vin,
    reading: vehicleState?.reading ? formatNumber(vehicleState?.reading) : '',
    units: vehicleState?.units ? formatToTitleCase(vehicleState?.units) : '',
    shortDescription: vehicleState?.shortDescription,
    interiorColor: vehicleState?.interiorNormalizedName,
    exteriorColor: vehicleState?.exteriorNormalizedName
  };

  const vehicleInfoProps: VehicleInfoProps = {
    vehicleInfo: vehicleInfo,
    onContinue: handleContinueClick,
    onBack: handleBackClick
  };

  return <VehicleInfoUI {...vehicleInfoProps} />;
};
export default VehicleInfo;
